/* Error modal */
.no-scroll {
  overflow: hidden;
  height: 100%;
}
/* dropdown menu */
/* Table */
.headline,
.description {
  position: relative;
  padding-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 1s linear infinite;
}
table {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

