@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  background: rgb(128, 15, 125);
  background: rgb(128,15,125);
  background: linear-gradient(180deg, #800f7d 0%, #29123f 49%, #090106 100%);
  background-size: cover;
  background-position: center;
}
label {
  font-size: 0.75rem !important;
}
img {
  pointer-events: none !important;
}
input,
textarea {
  height: 2rem;
  outline: none;
  font-size: 1rem;
  font-weight: 400 !important;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
